import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../Container'
import CTA from '../CTA'

const CTABlock = (props) => {
  const {
    data: {
      cta_button_href: buttonHref,
      cta_button_text: buttonText,
      cta_button_tip: buttonTip,
      cta_headline: headline,
      cta_subtext: subtext,
    },
    bgColor,
    ctaHref,
  } = props

  return (
    <Wrapper center bgColor={bgColor}>
      <FadeUp>
        <CTA
          align={'center'}
          buttonColor={'azure'}
          buttonContent={buttonText}
          buttonHref={buttonHref || ctaHref}
          buttonTip={buttonTip}
          buttonMobileWidth={'100%'}
          headline={headline}
          subtext={subtext}
          testID={'payoff-loan-cta'}
        />
      </FadeUp>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  flex-direction: row;
  text-align: center;
  background-color: ${ ({ theme, bgColor }) => bgColor || theme.colors.chartreuse[3] };
`

CTABlock.defaultProps = {
  bgColor: null,
  ctaHref: null,
}

CTABlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  bgColor: PropTypes.string,
  ctaHref: PropTypes.string,
}

export default CTABlock
