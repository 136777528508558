import Image from 'next/image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Headline from './Headline'
import Text from './Text'

const IconList = (props) => {
  const { items, className, descMaxWidth } = props

  return (
    <List className={className}>
      {items.map((item) => (
        <Item key={item.title}>
          <Image alt={item.icon.alt} src={item.icon.url} width={item.icon.dimensions.width} height={item.icon.dimensions.width} />
          <Title size="medium">{item.title}</Title>
          <Description descMaxWidth={descMaxWidth}>{item.description}</Description>
        </Item>
      ))}
    </List>
  )
}

const List = styled.ul`
  display: grid;
  padding: 0;
  margin: 0;
  gap: 100px;
  grid-column-gap: 160px;
  grid-template-rows: 1fr;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Item = styled.li`
  align-items: center;
  flex-direction: column;
  display: flex;
  max-width: 280px;
  text-align: center;
  list-style-type: none;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    max-width: 300px;
  }
`

const Title = styled(Headline)`
  margin: 0;
  margin-top: 32px;
`

const Description = styled(Text)`
  max-width: 280px;
  font-family: ${ ({ theme }) => theme.fonts.MabryProRegular };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    max-width: ${ ({ descMaxWidth }) => descMaxWidth };
  }
`

IconList.defaultProps = {
  className: '',
  descMaxWidth: '240px'
}

IconList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  descMaxWidth: PropTypes.string,
}

export default IconList
