import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'next/image'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../Container'
import Display from '../Display'
import Headline from '../Headline'

const Quotes = (props) => {
  const {
    data: {
      quotes,
      quotes_headline: headline,
      quotes_subtext: subtext,
    },
    extra: {
      items: newQuotes,
      primary
    }
  } = props

  return (
    <Wrapper sectionBg={primary?.section_background} maxWidth={'1440px'}>
      <FadeUp>
        <TextContent>
          <StyledDisplay size="small" testID="quotes-title" element="h2">{headline}</StyledDisplay>
          <StyledSubText size="small" testID="quotes-subtext">{subtext}</StyledSubText>
        </TextContent>

        <QuotesContainer>
          {(newQuotes?.length > 1 ? newQuotes : quotes).map((item, index) => (
            <Card bgColor={item.background_color} key={item.quote}>
              <StyledHeadlineQuote size="medium" testID={`quote-item-${ index + 1 }`}>{item.quote}</StyledHeadlineQuote>
              <BottomBox>
                <Name size="medium" data-testid={`quote-item-name-${ index + 1 }`}>{item.name}</Name>
                <AvatarWrapper>
                  <Image layout="responsive" quality={100} src={item.avatar?.url} width={item.avatar?.dimensions?.width} height={item.avatar?.dimensions.height} alt={item.avatar.alt} />
                </AvatarWrapper>
              </BottomBox>
            </Card>
          ))}
        </QuotesContainer>
      </FadeUp>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  position: relative;
  overflow: hidden;
  background-color: ${ ({ theme }) => theme.colors.azure[3] };
`

const TextContent = styled.div`
  flex-direction: column;
  z-index: 2;
  display: flex;
  text-align: center;
`

const StyledDisplay = styled(Display)`
  margin: 0 auto;
  margin-bottom: 18px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    margin: 0;
    margin-bottom: 24px;
  }
`

const StyledSubText = styled(Headline)`
  margin: 0;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.sm }) {
    max-width: 80%;
    margin: 0 auto;
  }
`

const StyledHeadlineQuote = styled(Headline)`
  font-family: ${ ({ theme }) => theme.fonts.MabryProLight };
`

const QuotesContainer = styled.div`
  display: grid;
  margin-top: 60px;
  gap: 24px 16px;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-top: 118px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const Card = styled.div`
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  min-height: 318px;
  padding: 24px;
  margin: 0 auto;
  background-color: ${ ({ bgColor }) => bgColor };
  border: 1px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 10px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.xs }) {
    width: 300px;
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    width: 400px;
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    width: auto;
    height: 440px;
    padding: 32px 48px;
  }
`

const BottomBox = styled.div`
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
`

const Name = styled(Headline)`
  margin: 0;
  font-weight: bold;
  line-height: 150%;
`
const AvatarWrapper = styled.div`
  align-self: flex-end;
  width: 60px;
  height: 60px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    width: 120px;
    height: 120px;
  }
`

Quotes.defaultProps = {
  extra: {
    section_background: null,
    show_background_icon: true,
    card_background: null
  }
}

Quotes.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  extra: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Quotes
